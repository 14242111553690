import React, { useEffect, useState } from "react";
import { Routes as Switch, Route, useLocation } from "react-router-dom";

import { useRecoilState, useSetRecoilState } from "recoil";
import { userInfo, userCredentials, rioNegroServices, snackbarData } from "./recoilState/GlobalState";

import { getUserCredentials } from "services/getUserCredentials";
import { checkAuth } from "services/checkAuth";
import { usuarioMinimosGet } from "services/services-mia";

import ProtectedRoute from "./components/route/ProtectedRoute";
import { CircularIndeterminate, Header } from "components";
import SnackBar from "components/utils/snackbars/Snackbar";

import {
    Dashboard,
    Usuarios,
    Login,
    UsuariosXServicio,
    UserDetailsDashboard
} from "./pages";

import { Routes as ROUTES } from "./consts/Routes-App";

import ServiceRoute from "components/route/ServiceRoute";
import { merchantLogoGet } from "services/services-mia/merchant";
import { RNServicioGet } from "services/services-mia/RNServicioGet";

import s from "./App.module.scss"
import DespachantesProgramaCalor from "pages/programa-calor/despachantes/index";
import BeneficiariosProgramaCalor from "pages/programa-calor/beneficiarios";
import BeneficiariosEntregaSociales from "pages/entregas-sociales/beneficiarios";
import DespachantesEntregaSociales from "pages/entregas-sociales/despachantes";
import InformesAsisteRN from "pages/entregas-sociales/informes";
import RubosEntregasSociales from "pages/entregas-sociales/rubros";
import ArticulosEntregasSociales from "pages/entregas-sociales/articulos";
import { subscribe, unsubscribe } from "./utils/eventManager.js";

export default function App() {
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [routeError, setRouteError] = useState(false);
    const setInfo = useSetRecoilState(userInfo);
    const setServices = useSetRecoilState(rioNegroServices);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        (async () => {
            const newCredentials = await getUserCredentials();
            if (newCredentials) {
                setCredentials(newCredentials);
                const userData = await usuarioMinimosGet(newCredentials);
                console.log("Esto es user info", userData);
                const { result: merchantData } = await merchantLogoGet({}, newCredentials);
                setInfo({ ...merchantData[0], ...userData });
                const { result: servicesData } = await RNServicioGet({}, newCredentials);

                setServices([...servicesData]);
            }
            setIsAuth(checkAuth());
            setLoading(false);
        })();
    }, [setCredentials, setInfo, setServices]);

    useEffect(() => {
        if (loading) return;
        setIsAuth(checkAuth());
    }, [credentials, loading]);
    useEffect(() => {
        const handleErrorServer = (data) => {
            setSnackBarInfo({
                message: data.message,
                severity: "warning",
                open: true,
            });
        };
        const handleSessionExpired = (data) => {
            setRouteError(ROUTES.login.route);
            setIsOpen(true);
        };
        subscribe("serverError", handleErrorServer);
        subscribe("sessionExpired", handleSessionExpired);
        return () => {
            unsubscribe("serverError", handleErrorServer);
            unsubscribe("sessionExpired", handleSessionExpired);
        };
    }, []);
    if (loading) return <CircularIndeterminate />;

    return (
        <div className={s.container}>
            <div className={setIsAuth.header}>
                {location.pathname !== ROUTES.login.route && <Header />}

            </div>
            <div className={s.body}>
                <SnackBar />

                <Switch>
                    <Route path={ROUTES.login.route} exact element={<Login />} />

                    <Route
                        element={
                            <ProtectedRoute
                                redirectProtectedRoute={ROUTES.login.route}
                                isAuth={isAuth}
                            />
                        }
                    >
                        <Route
                            path={ROUTES.dashboard.route}
                            exact
                            element={<Dashboard route={ROUTES.dashboard} />}
                        />
                        <Route
                            path={ROUTES.usuarios.route}
                            exact
                            element={<Usuarios route={ROUTES.usuarios} />}
                        />
                        <Route
                            path={"usuarios/:usuarioId/details"}
                            element={<UserDetailsDashboard />}
                        />

                        <Route
                            path="servicio/RNCALOR"
                            element={<ServiceRoute servicioCod="RNCALOR" redirectProtectedRoute={ROUTES.login.route} />}
                        >
                            <Route path="beneficiarios" element={<BeneficiariosProgramaCalor />} />
                            <Route path="despachantes" element={<DespachantesProgramaCalor />} />
                            <Route
                                path="usuarios"
                                element={<UsuariosXServicio />}
                            />

                        </Route>
                        <Route
                            path="servicio/RNES"
                            element={<ServiceRoute servicioCod="RNES" redirectProtectedRoute={ROUTES.login.route} />}
                        >
                            <Route path="beneficiarios" element={<BeneficiariosEntregaSociales />} />
                            <Route path="rubros" element={<RubosEntregasSociales />} />
                            <Route path="articulos" element={<ArticulosEntregasSociales />} />
                            <Route path="despachantes" element={<DespachantesEntregaSociales />} />
                            <Route
                                path="usuarios"
                                element={<UsuariosXServicio />}
                            />
                            <Route
                                path="informes"
                                element={<InformesAsisteRN />}
                            />

                        </Route>

                        <Route
                            path="/*"
                            element={<Dashboard />}
                        />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
