import { React, useState, useEffect } from "react";

import { Collapse, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { InsideDrawerNav } from "./InsideDrawerNav";

import { Routes, servicesRoutes } from "consts/Routes-App";

import { useRecoilValue } from "recoil";
import { rioNegroServices } from "recoilState/GlobalState";

import styles from "./ListDrawer.module.scss";
import { ExpandLess, ExpandMore, HomeRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";

import * as iconServicios from "assets/icons/servicios";
import PropaneTankIcon from '@mui/icons-material/PropaneTank';
export default function ListDrawer({ setOpen }) {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const services = useRecoilValue(rioNegroServices);

    console.log("Mia services", services);

    const handleClick = () => {
        setOpen(false);
    };

    useEffect(() => {
        setIsDrawerOpen(services.filter((service) => (service.servicioAutorizado === "Y")).map(() => false))
    }, [services])
    console.log(services[1]);
    return (
        <>
            {services && (
                <List
                    aria-labelledby="nested-list-subheader"
                    className={styles.root}
                    component="nav"
                >
                    <Link to={Routes.dashboard.route}>
                        <ListItem
                            className={styles.itemList}
                            onClick={handleClick}
                        >
                            <ListItemIcon>
                                <HomeRounded className={styles.iconsDrawer} />
                            </ListItemIcon>
                            <span className={styles.textColor}>Home</span>
                        </ListItem>
                    </Link>
                    {(
                        <span key={services[1]?.servicioCod}>
                            <ListItemButton
                                className={styles.itemList}
                                onClick={() => setIsDrawerOpen({ ...isDrawerOpen, [services[1]?.servicioCod]: !isDrawerOpen[services[1]?.servicioCod] })}
                            >
                                <ListItemIcon>
                                    <>
                                        <img
                                            alt={services[1]?.servicioNombre}
                                            style={{ color: isDrawerOpen ? "#FF6D00" : "#FFFFFF" }}
                                            className={styles.iconsDrawer}
                                            src={iconServicios[services[1]?.servicioCod]}
                                        />
                                    </>
                                </ListItemIcon>
                                <ListItemText className={isDrawerOpen[services[1]?.servicioCod] ? styles.textColorSelect : styles.textColor}>
                                    {services[1]?.servicioNombre}
                                </ListItemText>
                                {isDrawerOpen[services[1]?.servicioCod] ? <ExpandLess style={{ color: "#FF6D00" }} /> : <ExpandMore style={{ color: "#FFFFFF" }} />}
                            </ListItemButton>
                            <Collapse in={isDrawerOpen[services[1]?.servicioCod]} timeout="auto">
                                <List component="div">
                                    {
                                        servicesRoutes[services[1]?.servicioCod]?.map((menu, index) => (
                                            <InsideDrawerNav
                                                key={index}
                                                menu={menu}
                                                className={styles.link}
                                                handleClick={handleClick}
                                                route={`servicio/${services[1]?.servicioCod}`}
                                            />
                                        ))
                                    }
                                </List>
                            </Collapse>
                        </span>
                        // {services.map((service, index) => (
                        //     service.servicioAutorizado === "Y" && (
                        //         <span key={service.servicioCod}>
                        //             <ListItemButton
                        //                 className={styles.itemList}
                        //                 onClick={() => setIsDrawerOpen({ ...isDrawerOpen, [service.servicioCod]: !isDrawerOpen[service.servicioCod] })}
                        //             >
                        //                 <ListItemIcon>
                        //                     {
                        //                         service.servicioCod === "RNCALOR" ?
                        //                             (
                        //                                 <>
                        //                                     <Icon>
                        //                                         <PropaneTankIcon sx={{ color: "#FFFFFF" }} />
                        //                                     </Icon>
                        //                                 </>
                        //                             ) : (
                        //                                 <>
                        //                                     <img
                        //                                         alt={service.servicioNombre}
                        //                                         style={{ color: isDrawerOpen ? "#FF6D00" : "#FFFFFF" }}
                        //                                         className={styles.iconsDrawer}
                        //                                         src={iconServicios[service.servicioCod]}
                        //                                     />
                        //                                 </>

                        //                             )
                        //                     }

                        //                 </ListItemIcon>
                        //                 <ListItemText className={isDrawerOpen[service.servicioCod] ? styles.textColorSelect : styles.textColor}>
                        //                     {service.servicioNombre}
                        //                 </ListItemText>
                        //                 {isDrawerOpen[service.servicioCod] ? <ExpandLess style={{ color: "#FF6D00" }} /> : <ExpandMore style={{ color: "#FFFFFF" }} />}
                        //             </ListItemButton>
                        //             <Collapse in={isDrawerOpen[service.servicioCod]} timeout="auto">
                        //                 <List component="div">
                        //                     {
                        //                         servicesRoutes[service.servicioCod]?.map((menu, index) => (
                        //                             <InsideDrawerNav
                        //                                 key={index}
                        //                                 menu={menu}
                        //                                 className={styles.link}
                        //                                 handleClick={handleClick}
                        //                                 route={`servicio/${service.servicioCod}`}
                        //                             />
                        //                         ))
                        //                     }
                        //                 </List>
                        //             </Collapse>
                        //         </span>
                        //     )
                    )}
                </List>
            )}
        </>
    );
}
