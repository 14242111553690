import axios from "axios";
import { serviceURL } from "../../consts/ServiceURL";
import { APLICACION_COD, X_API_KEY } from "consts/env";
import { fallbackURL } from "consts/FallbackURL";
import { emit } from "utils/eventManager";

export default async function axiosPost(
    body,
    credentials = false
) {
    const headers = {
        "Content-Type": "application/json",
    };

    if (credentials) {
        headers["x-hooli-token"] = credentials.hooliToken || credentials.token;
        headers["x-session-id"] = credentials.sessionId;
    }

    headers["x-api-key"] = X_API_KEY;
    headers["x-origin-id"] = APLICACION_COD;

    try {
        const { data: result } = await axios.post(serviceURL, body, { headers });
        if (!result || result === null || result === undefined) {
            return {
                status: {
                    code: 0,
                    errcode: 99999,
                    errmsg: "Error inesperado en request",
                    action: "U",
                    id: null,
                },
                result: [],
            };
        }

        if (result?.status?.code !== 1 && result?.status?.errcode === 10000) {
            localStorage.clear();
            return window.location.assign(fallbackURL);
        }

        return result;
    } catch (err) {
        return {
            status: {
                code: 0,
                errcode: err.response ? err.response.status : 0,
                errmsg: err.message,
                action: "U",
                id: null,
            },
            result: [],
        };
    }
}
axios.interceptors.response.use(
    (response) => {
        // console.log(
        //     "Estos son los interceptors",
        //     response,
        //     window.sessionStorage.getItem("hooliToken")
        // );

        // if (
        //     response.data?.status?.errmsg ===
        //     "Su sesión ha expirado por falta de actividad"
        // ) {

        //     emit("sessionStorageChanged", {
        //         hooliToken: 0,
        //         sessionId: 0,
        //     });
        // }

        if (response.data?.status?.code != 1) {
            switch (response.data?.status?.action) {
                case "X": {
                    if (
                        response.data?.status?.errcode === 10000
                    ) {
                        window.sessionStorage.setItem("hooliToken", "");
                        window.sessionStorage.setItem("sessionId", "");
                        return emit("sessionExpired", {
                            route: "/login",
                            message: response.data.status.errmsg,
                        });
                    }
                    return response;
                }
                case "W": {
                    if (
                        response.data?.status?.errcode === 10000
                    ) {
                        window.sessionStorage.setItem("hooliToken", "");
                        window.sessionStorage.setItem("sessionId", "");
                        return emit("sessionExpired", {
                            route: "/login",
                            message: response.data.status.errmsg,
                        });
                    }
                    if(response.data?.status?.errcode === 70101){
                        return emit("serverError", {
                            message: response.data.status.errmsg,
                        });
                    }
                    return emit("serverError", {
                        message: response.data.status.errmsg,
                    });
                }
                case "E": {
                    if (
                        response.data?.status?.errcode === 10000
                    ) {
                        window.sessionStorage.setItem("hooliToken", "");
                        window.sessionStorage.setItem("sessionId", "");
                        return emit("sessionExpired", {
                            route: "/login",
                            message: response.data.status.errmsg,
                        });
                    }
                    return emit("serverError", {
                        route: "/login",
                        message: "Error de servidor",
                    });
                }
                default: {
                    return emit("serverError", {
                        message: response.data.status.errmsg,
                    });
                }
            }
        }

        return response;
    },
    (error) => {}
);