import axiosPost from "./axiosPost";

export const RNESAsignacionesGet = async (credentials, data) => {
    const body = {
        service: "RNESAsignacionGet",
        params: {
            usuarioId: data?.usuarioId,
            rnesAsignacionId: data?.asignacionId,
        },
    };

    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
