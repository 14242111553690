import React, { useCallback, useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { connectionIns, usuarioBasicosGet } from "services/services-mia";
import { RNConnectionIns } from "services/services-mia/RNConnectionIns";

const sexos = [
    { sexoCod: "M", sexoNombre: "Masculino" },
    { sexoCod: "F", sexoNombre: "Femenino" },
    { sexoCod: "X", sexoNombre: "No Binario" }
];



export default function InsertModal({ updateList, servicio, closeModal }) {
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);

    const [placeholderDNI, setPlaceholderDNI] = useState('')
    const [regexDNI, setRegexDNI] = useState(/./)

    const formikRef = useRef();

    const functions = {
        setSnackbarInfo,
        setInRequest,
        setIsOpen: closeModal,
        updateList,
    };

    const handleConnection = async (values, resetForm, setFieldValue) => {
        setInRequest(true);

        const data = {
            servicioHooliCod: servicio.servicioCod,
            rolCod: values.rol,
            usuarioNroDeDocumentoX: values.dni,
            usuarioSexo: values.genero,
            usuarioMail: null,
            usuarioCelular: null,

        }

        const responseIns = await RNConnectionIns(
            data,
            credentials
        );

        handleResponse(
            responseIns,
            "Conexión creada exitosamente",
            functions
        );
        resetForm()



        setInRequest(false);
        return;
    };



    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                dni: "",
                genero: sexos[0].sexoCod,
                rol: "BEN",
            }}
            validationSchema={
                yup.object({
                    dni: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required)
                        .matches(regexDNI, { message: "Número de documento inválido" }),
                })
            }
            onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {

                try {

                    await handleConnection(values, resetForm, setFieldValue);


                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>


                        <FormikTextInput
                            fullWidth={true}
                            name="dni"
                            placeholder={placeholderDNI}
                            labelText="Número de DNI"
                        />
                        <FormikSelectInput
                            fullWidth={true}
                            name="genero"
                            labelText="Género"
                        >
                            {sexos.map(({ sexoCod, sexoNombre }) => (
                                <option
                                    key={sexoCod}
                                    data-id={sexoCod}
                                    value={sexoCod}
                                >
                                    {sexoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>

                    </>



                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Guardar cambios" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
